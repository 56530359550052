@import '../../../assets/styles/variables';

.orderListContainer {
  flex: 0 0 20%;
  max-width: 20%;
  //width: 10em;
  //height: 10em;
  overflow: auto;
  visibility: hidden;
  .orderListContent {
    visibility: visible;
  }

  .orderFilterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
    min-height: 20px;
  }

  .deletePromptWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .deleteText {
    font-size: 12px;
    color: #3cbaa8;
  }

  .deletePromptIcon {
    //margin: 0 5px;
    padding: 2px;
    margin-right: 5px;
    margin-left: 5px;
    height: 13px;
    width: 13px;
  }
  .deletePromptIcon:hover {
    color: #3cbaa8;
  }

}

.orderListContainer:hover,
.orderListContainer:focus {
  visibility: visible;
}

.orderListContainer_delayed {
  transition: visibility 0.2s;
}

.orderListContainer_delayed:hover {
  transition: visibility 0s 0.2s;
}
