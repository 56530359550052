.largeImageViewContainer {
  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1200;
  }

  .content {
    height: 100%;
    background-color: rgba(164, 164, 164, 0.9);
  }

  @keyframes :local(imageEnlarge) {
    to { left: 0px; top: 0px; bottom: 0px; right: 0px; }
  }

  .imageContainer {
    display: flex;
    animation-name: imageEnlarge;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    align-items: center;
    justify-content: center;
    padding: 5%;
  }

  .image {
    max-height: 75vh;
    //max-width: 95%;
    border-radius: 12px;
  }
}
