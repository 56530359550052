@import '../../../assets/styles/variables';

.modalContainer {
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $primary;
    }
    img {
        height: 60px;
    }
    .title {
        font-size: 20px;
        font-weight: 400;
        margin-left: 10px;
        color: #363636;
    }
    .description {
        color: #434343;
        font-size: 15px;
    }
    .selectBox {
        border: 1px solid $primary;
        padding: 10px;
        border-radius: 5px;
        margin: 15px 0 0;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        .desc {
            color: #434343;
            font-size: 15px;
        }
        input[type='file'] {
            position: absolute;
            top: 0;
            right: 0;
            min-width: 100%;
            min-height: 100%;
            font-size: 100px;
            text-align: right;
            filter: alpha(opacity=0);
            opacity: 0;
            outline: none;
            cursor: inherit;
            display: block;
        }
    }
    .selectBox:hover {
        background: $disabledPrimary;
    }
    .errorMsg {
        color: $danger;
        font-size: 14px;
        font-weight: bold;
        padding: 20px 5px;
        border-bottom: 1px solid $primary;
    }
    .headline {
        color: $primary;
        margin-bottom: 10px;
        font-size: 15px;
    }
}
