.chatMessagesInputViewContainer {
  position: fixed;
  right: 0;
  left: 20%;
  bottom: 0;
  background: #f3f4f6;
  box-shadow: -2px -16px 21px -10px white;
  .textBoxContainer {
    //position: relative;
    //bottom: 0;
    //right: 0;
    //left: 0;
    //background: #f3f4f6;
    //box-shadow: -2px -32px 31px -10px rgba(255, 255, 255, 1);
    .boxWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
    .sendIcon {
      position: absolute;
      right: 35px;
      height: 30px;
      cursor: pointer;
    }

    .chatInput {
      width: 100%;
      font-size: 17px;
      padding: 12px 70px 12px 12px;
      outline: none;
      border: 1px solid #eee;
      resize: none;
      border-radius: 10px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
  }

}
