@import '../../../assets/styles/variables';

.orderViewContainer {
  flex: 0 0 80%;
  max-width: 80%;
  //height: 90vh;
  background-color: rgba(102, 115, 144, 0.08);
  position: relative;
  .upperView {
    position: relative;
    flex: 1 1 auto;
    align-self: stretch;
    overflow: auto;
    height: 75vh;
    padding: 12px;
  }
  .infoMsg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    color: $text-secondary;
    font-size: 15px;
  }
}
